<template>
	<div>
		<Chart :cdata="cdata"/>
	</div>
</template>

<script>
import Chart from './chart.vue';
export default {
	components: {
		Chart
	},
	props: {
		data: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			cdata: {}
		}
	},
	watch: {
		"data": {
			handler(data) {
				/* x坐标 */
				this.cdata.category = data.map(item => item.time)
				/* 温度 */
				this.cdata.temperature = data.map(item => item.temperature)
				/* 湿度 */
				this.$set(this.cdata,"humidity",data.map(item => item.humidity))
			},
			deep: true,
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped></style>
