<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 style="width:33.3%;height:.0625rem;"/>
          <div class="d-flex jc-center">
            <dv-decoration-8 :color="['#568aea', '#000000']" style="width:2.5rem;height:.625rem;"/>
            <div class="title">
              <span class="title-text">大数据可视化平台</span>
              <dv-decoration-6 class="title-bototm" :reverse="true" :color="['#50e3c2', '#67a1e5']"
                               style="width:3.125rem;height:.1rem;"/>
            </div>
            <dv-decoration-8 :reverse="true" :color="['#568aea', '#000000']" style="width:2.5rem;height:.625rem;"/>
          </div>
          <dv-decoration-10 style="width:33.3%;height:.0625rem; transform: rotateY(180deg);"/>
        </div>

        <!-- 第二行：导航 & 日期 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex" style="width: 40%">
            <div class="react-right ml-4" style="width: 6.25rem; text-align: left;background-color: #0f1325;">
              <span class="react-before"></span>
              <a class="text" href="http://cygao.top" style="color: #5cd9e8">关于作者</a>
<!--              <span class="text">数据分析1</span>-->
            </div>
            <div class="react-right ml-3" style="background-color: #0f1325;">
              <a href="https://gitee.com/MTrun/big-screen-vue-datav" class="text" style=" color: #5cd9e8;">特别鸣谢</a>
            </div>
          </div>
          <div style="width: 40%" class="d-flex">
            <div class="react-left bg-color-blue mr-3"><span class="text fw-b">环境数据监测</span></div>
            <div class="react-left mr-4" style="width: 6.25rem; background-color: #0f1325; text-align: right;">
              <span class="react-after"></span>
              <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>
          </div>
        </div>

        <div class="body-box">
          <div class="bototm-box">
            <dv-border-box-13>
              <bottomLeft :data="arr_leftData"/>
            </dv-border-box-13>
            <dv-border-box-12>
              <bottomRight :data="arr_rightData"/>
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import {formatTime} from '../utils/index.js';
import {api_getData} from "@/utils/request.js"
import bottomLeft from './bottomLeft';
import bottomRight from './bottomRight';

export default {
  data() {
    return {
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      arr_leftData: [],
      arr_rightData: []
    };
  },
  components: {
    bottomLeft,
    bottomRight
  },
  created() {
    api_getData({count: 20})
        .then(res => {
          this.arr_leftData = []
          this.arr_rightData = []
          res.data.forEach(item => {
            this.arr_leftData.push({
              humidity: item.humidity,
              temperature: item.temperature,
              time: formatTime(item.time, 'HH: mm: ss')
            })
            this.arr_rightData.push({
              aqi: item.aqi,
              lightLux: item.lightLux,
              time: formatTime(item.time, 'HH: mm: ss')
            })
          })
        })
        .finally(() => this.loading = false)
    setInterval(() => {
      this.pollData()
    }, 2000)
  },
  mounted() {
    this.timeFn()
  },
  methods: {
    /**
     * 轮询查收最新消息
     */
    pollData() {
      api_getData({count: 1})
          .then((res) => {
            const time = formatTime(new Date(), 'HH: mm: ss')
            const data = res.data[0]
            /* 虚拟数据 */
            this.arr_leftData.shift()
            this.arr_leftData.push({
              humidity: data.humidity,
              temperature: data.temperature,
              time: time
            })
            this.arr_rightData.shift()
            this.arr_rightData.push({
              aqi: data.aqi,
              lightLux: data.lightLux,
              time: time
            })
          })
    },
    timeFn() {
      setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss');
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
@import '../assets/scss/index.scss';
</style>
