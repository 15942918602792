<template>
	<div>
		<Chart :cdata="cdata" />
	</div>
</template>

<script>
import Chart from './chart.vue';
export default {
	components: {
		Chart
	},
	props: {
		data: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			cdata: {},
		};
	},
	watch: {
		"data": {
			handler(data) {
				/* x坐标 */
				this.cdata.category = this.data.map(item => item.time)
				/* 空气质量 */
				this.cdata.aqi = this.data.map(item => item.aqi)
				/* 湿度 */
				this.$set(this.cdata,"lightLux",data.map(item => item.lightLux))
			},
			deep: true,
			immediate: true
		}
	}
}
</script>

<style lang="scss" scoped></style>
