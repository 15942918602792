<template>
	<div>
		<!-- 温湿度 -->
		<Echart :options="options" id="bottomLeftChart" height="6.25rem" width="100%"></Echart>
	</div>
</template>

<script>
import Echart from '@/common/echart';
export default {
	components: {
		Echart
	},
	props: {
		cdata: {
			type: Object,
			default: () => {}
		}
	},
	data() {
		return {
			options: {}
		};
	},
	watch: {
		"cdata": {
			handler(cdata) {
				this.options = {
					title: {
						text: ''
					},
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(255,255,255,0.1)',
						axisPointer: {
							type: 'shadow',
							label: {
								show: true,
								backgroundColor: '#7B7DDC'
							}
						}
					},
					legend: {
						data: ['空气质量', '光照强度'],
						textStyle: {
							color: '#B4B4B4'
						},
						top: '0%'
					},
					grid: {
						x: '8%',
						width: '88%',
						y: '4%'
					},
					xAxis: {
						data: cdata.category,
						axisLine: {
							lineStyle: {
								color: '#B4B4B4'
							}
						},
						axisTick: {
							show: false
						}
					},
					yAxis: [
						{
							splitLine: { show: false },
							axisLine: {
								lineStyle: {
									color: '#B4B4B4'
								}
							},
				
							axisLabel: {
								formatter: '{value} '
							}
						},
						{
							splitLine: { show: false },
							axisLine: {
								lineStyle: {
									color: '#B4B4B4'
								}
							},
							axisLabel: {
								formatter: '{value} '
							}
						}
					],
					series: [
						{
							name: '空气质量',
							type: 'line',
							smooth: true,
							showAllSymbol: true,
							symbol: 'emptyCircle',
							symbolSize: 8,
							yAxisIndex: 1,
							itemStyle: {
								normal: {
									color: '#F02FC2'
								}
							},
							data: cdata.aqi
						},
						{
							name: '光照强度',
							type: 'bar',
							barWidth: 10,
							itemStyle: {
								normal: {
									barBorderRadius: 5,
									color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{ offset: 0, color: '#956FD4' }, { offset: 1, color: '#3EACE5' }])
								}
							},
							data: cdata.lightLux
						}
					]
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
