import axios from "axios"

function request(url, method, data) {
    return axios.request({
        baseURL: " https://cygao.top/ams",
        url,
        method,
        timeout: 10000,
        data: method === "GET" ? null : data,
        params: method === "GET" ? data : null,
    })
        .then(res => {
            return res.data
        })
        .catch(err => {
            return err
        })
}

/**
 * 获取数据
 * @param {Number}  count
 */
export const api_getData = (params) => request("/monitor/data/1", "GET", params)